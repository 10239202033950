import React, { useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';

import ReactGA from 'react-ga4';
const trackingId = 'G-1CFWKJR81V';
ReactGA.initialize(trackingId);

const Wrapper = (props) => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [props, location.pathname]);
  return <>{props.children}</>;
};



export default withRouter(Wrapper);
