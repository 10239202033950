import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const Footer = () => {
  return (
    <>
      <footer
        style={{
          //marginTop: '30px',
          marginBottom: '20px',
          borderTop: '3px solid #FFFF00',
          borderBottom: '3px solid #FFFF00',
          backgroundColor: '#3097E9',
          paddingTop: '50px',
          paddingBottom: '10px',
          color: 'white',
        }}
      >
        <Container>
          <Grid container>
            <Grid style={{ marginTop: '30px' }} item md={6} xs={12}>
              <Typography
                style={{
                /*  borderBottom: '1px solid #eee', */
                }}
                variant="h6"
              >
                <b>CORPORATE OFFICE</b>
              </Typography>
              <Typography style={{ color: 'white' }} variant="body2">
                Unit 219, Suncity Success Tower <br />
                Gurugram, Haryana,
                <br /> India – 122018 <br />
                <br />
                9813430063 | vindhyaindustrieshisar@gmail.com
              </Typography>
            </Grid>
           
            <Grid style={{ marginTop: '30px' }} item md={6} xs={12}>
              <Typography
                style={{
                /*  borderBottom: '1px solid #eee', */
                }}
                variant="h6"
              >
                <b>PLANT</b>
              </Typography>
              <Typography style={{ color: 'white' }} variant="body2">
                6<sup>th</sup> km. stone, Delhi Road <br />
                Hisar, Haryana,
                <br /> India – 125005 <br />
                <br />
                9813230977 | vindhyaindustrieshisar@gmail.com
              </Typography>
            </Grid>

            <Grid style={{ marginTop: '30px' }} item md={6} xs={12}>
              <Typography
                style={{
                /*  borderBottom: '1px solid #eee', */
                }}
                variant="h6"
              >
               <b> HOURS</b>
               </Typography>
              <Typography style={{ color: 'white' }} variant="body2">
                Monday—Friday: 10:00AM–6:00PM <br />
                Saturday : 11:00AM–3:00PM
              </Typography>
              <br />
              <br />
              <Grid>
                <FacebookIcon
                  style={{ fontSize: '2 em', marginRight: '10px' }}
                />
                <LinkedInIcon
                  style={{ fontSize: '2 em', marginRight: '10px' }}
                />
                <WhatsAppIcon style={{ fontSize: '2 em' }} />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
