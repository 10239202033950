import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import ReactPixel from 'react-facebook-pixel';
const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

ReactPixel.init('850620728906118', advancedMatching, options);

ReactPixel.pageView(); // For tracking page view

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
