import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

// Loader
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';

//import ReactGA from 'react-ga';
//import { createBrowserHistory } from 'history';

import Navbar from './Components/Homepage/Navbar';
import Footer from './Components/Homepage/footer';
import Wrapper from './Components/wrapper';

//const trackingId = 'UA-169311920-1';

const Homepage = React.lazy(() => import('./pages/homepage'));
const AboutUs = React.lazy(() => import('./pages/aboutus'));
const Product = React.lazy(() => import('./pages/products'));
const Applications = React.lazy(() => import('./pages/application'));
const Faq = React.lazy(() => import('./pages/faq'));
const Career = React.lazy(() => import('./pages/career'));
const Contact = React.lazy(() => import('./pages/contact'));
const ApplicationForm = React.lazy(() => import('./pages/applicationform'));

//ReactGA.initialize(trackingId);

//const history = createBrowserHistory();

//ReactGA.pageview(window.location.pathname + window.location.search);

// //Initialize google analytics page view tracking
// history.listen((location) => {
//   alert('hello');
//   ReactGA.set({ page: location.pathname });
//   console.log('hello', location.pathname); //Update the user's current page
//   ReactGA.pageview(location.pathname); // Record a pageview for the given page
// });

// function usePageViews() {
//   let location = useLocation();
//   console.log(location);
//   React.useEffect(() => {
//     alert('page changed');
//     ReactGA.pageview(location.pathname);
//   }, [location]);
//   return <></>;
// }

function App() {
  return (
    <Router onUpdate={() => alert('update')}>
      <Navbar />
      <Suspense
        fallback={
          <div style={{ paddingTop: '200px', textAlign: 'center', overflowX: 'hidden'}}>
            <Loader
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              timeout={3000} //3 secs
            />
          </div>
        }
      >
        <Switch>
          <Route path="/" exact>
            <Wrapper>
              <Homepage />
            </Wrapper>
          </Route>
          <Route path="/about-us-vindhya" exact>
            <Wrapper>
              <AboutUs />
            </Wrapper>
          </Route>
          <Route path="/vindhya-pipes" exact>
            <Wrapper>
              <Product />
            </Wrapper>
          </Route>
          <Route path="/application-vindhya-pvc-pipes" exact>
            <Wrapper>
              <Applications />
            </Wrapper>
          </Route>
          <Route path="/faq" exact>
            <Wrapper>
              <Faq />
            </Wrapper>
          </Route>
          <Route path="/career-vindhya" exact>
            <Wrapper>
              <Career />
            </Wrapper>
          </Route>
          <Route path="/contact-vindhya" exact>
            <Wrapper>
              <Contact />
            </Wrapper>
          </Route>
          <Route path="/application-form" exact>
            <Wrapper>
              <ApplicationForm />
            </Wrapper>
          </Route>
          <Redirect to="/" />
        </Switch>
      </Suspense>
      <Footer />
    </Router>
  );
}

export default App;
