import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { sizing } from '@material-ui/system';

import Logo from '../../assests/JPEG/Logo/logo.png';
import LogoWebp from '../../assests/WebP/Logo/Logo.webp';
import ImageWebp from '../ImageWebp';

import MenuIcon from '@material-ui/icons/Menu';
import Box from '@material-ui/core/Box';

import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  root: {
   // flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
   // flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginRight: '15px',
    },
  },
  navbar: {
    backgroundColor: '#FFFF00',
    color: 'black',
    position: 'sticky', //fixed
    zIndex: 9999,
    boxShadow: '0 4px 8px rgba(0,152,255,0.3)',
  },
  button: {
    fontWeight: 'bold',
    backgroundColor: '#3097E9',
    width: 100,
    height: 50,
    borderRadius: 8,
    borderWidth: 10,
    borderRightWidth: 15,
    margin: 5,
    color: 'white',
    transition: 'all 0.5s',
    '&:hover': {
      color: 'white',
      backgroundColor: 'black',
    },
  },
  imageCentered: {
    [theme.breakpoints.down['sm']]: {
      textAlign: 'center',
    },
  },
}));

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const navLinks = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'About Us',
      link: '/about-us-vindhya',
    },
    {
      text: 'Products',
      link: '/vindhya-pipes',
    },
    {
      text: 'Solutions',
      link: '/application-vindhya-pvc-pipes',
    },
    {
      text: 'FAQ',
      link: '/faq',
    },
    {
      text: 'Career',
      link: '/career-vindhya',
    },
    {
      text: 'Contact',
      link: '/contact-vindhya',
    },
  ];
  const classes = useStyles();
  return (
    <navbar>
      <Drawer
        style={{ zIndex: '99999', marginTop: '100px', padding: '20px'}}
        anchor={'left'}
        open={menuOpen}
        onClose={toggleMenu}
      >
        {navLinks.map((link) => (
          <ListItem button key={link.text} style={{ marginTop: '10px' }}>
            <ListItemText>
              <Link
                onClick={toggleMenu}
                to={link.link}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                {link.text}
              </Link>
            </ListItemText>
          </ListItem>
        ))}
      </Drawer>
      <AppBar className={classes.navbar}>
      
        <Toolbar>
          <Box component="span" display={{ sm: 'block', md: 'none' }}>
            <MenuIcon onClick={toggleMenu} />
          </Box>
          <Typography variant="h6" className={classes.title}>
            <ImageWebp
              style={{ width: '80%', maxWidth: '400px', padding: '25px', }}
              src={Logo}
            //  srcWebp={LogoWebp}
              alt="Vindhya Industries"
            />
          </Typography>

          <Box component="div" display={{ xs: 'none', md: 'block' }}>
            {navLinks.map((data) => (
              <Button className={classes.button} color="inherit">
                <Link
                  style={{ textDecoration: 'none', color: 'inherit'}}
                  to={data.link}
                >
                  {data.text}
                </Link>
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
    </navbar>
  );
};

export default Navbar;
